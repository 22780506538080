import { ref, watch, computed } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useImageSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    statusList: [{
      upload: '已上傳', convert: '轉碼中', finished: '開放下載', fail: '轉碼失敗',
    }, {
      upload: 'light-info', convert: 'light-warning', finished: 'light-success', fail: 'light-danger',
    }],
  }

  const imageTypeOptions = [
    { label: 'JPEG', value: 'jpg', type: 'image/jpeg' },
    { label: 'GIF', value: 'gif', type: 'image/gif' },
    { label: 'PNG', value: 'png', type: 'image/png' },
    { label: 'TIFF', value: 'tiff', type: 'image/tiff' },
    { label: 'BMP', value: 'bmp', type: 'image/bmp' },
    { label: 'ICO', value: 'ico', type: 'image/x-icon' },
    { label: 'PSD', value: 'psd', type: 'image/vnd.adobe.photoshop' },
    { label: 'WebP', value: 'webp', type: 'image/webp' },
  ]

  const imageTypeList = {}
  imageTypeOptions.forEach(option => {
    imageTypeList[option.type] = option.value
  })

  const imageType = ref('png')
  const imageResize = ref(false)
  const imageHeight = ref(null)
  const imageWidth = ref(null)

  const getMetaListData = () => store.dispatch('api/getMetaList')

  const getImageData = (...arg) => store.dispatch('admin-page-image/getImageData', ...arg)
  const setImageReload = (...arg) => store.dispatch('admin-page-image/setImageReload', ...arg)
  const setImageUpdate = (...arg) => store.dispatch('admin-page-image/setImageUpdate', ...arg)
  const setImageDelete = (...arg) => store.dispatch('admin-page-image/setImageDelete', ...arg)
  const setImageUpload = (...arg) => store.dispatch('admin-page-image/setImageUpload', ...arg)
  const getImageDownload = (...arg) => store.dispatch('admin-page-image/getImageDownload', ...arg)

  return {
    ui,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,

    getImageData,
    setImageDelete,
    setImageUpdate,
    setImageReload,
    setImageUpload,
    getImageDownload,

    imageTypeOptions,
    imageTypeList,
    imageType,
    imageResize,
    imageHeight,
    imageWidth,
  }
}

export const useImageList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    // dataMeta,
    // refetchData,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    // {
    //   label: '狀態', key: 'status', sortable: true, searchable: true, select: true,
    // },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '原始類型', key: 'extension', sortable: true, searchable: true, select: true,
    },
    {
      label: '原始大小', key: 'size', sortable: true, searchable: true, select: true,
    },
    {
      label: '轉檔類型', key: 'convert_extension', sortable: true, searchable: true, select: true,
    },
    {
      label: '轉檔大小', key: 'convert_size', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const tableData = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = totalNum.value
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value > totalNum.value ? totalNum.value : perPage.value * (currentPage.value - 1) + perPage.value,
      of: totalNum.value,
    }
  })
  const timeRange = ref(null)

  const blankImageData = {
    id: null,
    name: null,
    high: 0,
    width: 0,
    size: 0,
    status: 'upload',
    extension: null,
    convert_extension: null,
    convert_size: 0,
    created_at: null,
    busy: false,
  }

  const {
    syncObject,
  } = useCommenSettings()

  const responseNum = ref(0)

  // 設定計時器
  const setTableTimer = number => {
    responseNum.value = number !== undefined ? number : tableData.value.length
    const forNum = number !== undefined ? number : tableData.value.length
    for (let i = 0; i < forNum; i += 1) {
      const resolve = tableData.value[i]
      if (resolve.busy) {
        setTimeout(() => {
          const indexOfEl = tableData.value.findIndex(el => el.id === resolve.id)
          store.dispatch('admin-page-image/setImageReload', {
            image_id: resolve.id,
          })
            .then(response => {
              const uploadData = response.data.data
              const resolveData = {
                ...syncObject(blankImageData, uploadData),
              }
              if (resolveData.status === 'convert') {
                resolveData.status = 'upload'
                resolveData.busy = false
              }
              if (indexOfEl !== -1) {
                tableData.value.splice(indexOfEl, 1, resolveData)
              }
            })
            .catch(() => {
              tableData.value[indexOfEl].status = 'upload'
              tableData.value[indexOfEl].busy = false
            })
        }, 500)
      }
      responseNum.value -= 1
    }
  }

  const getImageListData = () => { // ctx, callback
    isBusy.value = true
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }

    store.dispatch('admin-page-image/getImageList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      // range: resolveTimeRange,
      // filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankImageData, item),
          }
          if (resolve.status === 'convert') {
            resolve.busy = true
          }
          return resolve
        })
        // callback(resolveData)
        tableData.value = resolveData
        totalNum.value = total
        setTableTimer()
        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getImageListData()
  }

  const refetchData = () => getImageListData()

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    getImageListData()
  })

  return {
    isBusy,
    tableData,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    blankImageData,
    refetchData,
    refetchTable,

    // setTodoClassCreate,
    // setTodoClassUpdate,
    getImageListData,
    responseNum,
    setTableTimer,
    useHttpCodeAlert,
  }
}
