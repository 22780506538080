<template>
  <div>
    <b-card no-body>
      <div
        ref="uploadCardContent"
      >
        <div
          ref="uploadCard"
          no-body
          class="image-explorer-contain upload-card"
          @dragenter="dragEnter"
          @dragover="preventDefault"
          @dragleave="dragLeave"
          @drop="dropFile"
        >
          <div
            ref="dropArea"
            class="dropArea hidden"
          >
            <div class="upload-label">
              <span class="label-title">
                把圖片拖放到這裡
              </span>
              <b-img
                :src="require('@/assets/images/icons/image-processing.png')"
                fluid
              />
            </div>
          </div>

          <div class="border m-1">
            <label class="upload-label-text">
              <span class="mb-1">
                把圖片拖放到這裡或者 <strong class="cursor-pointer text-primary">點擊這裡</strong> 選擇檔案
              </span>
              <input
                ref="fileInput"
                type="file"
                multiple
                accept=".jpg, .png, .gif .tiff, .bmp .ico .psd .webp"
                hidden
                @input="resolveFileInput($event)"
              >
              <div>
                <b-img
                  :src="require('@/assets/images/icons/image-processing.png')"
                  fluid
                  class="events-content"
                />
              </div>
            </label>
          </div>

          <!-- <div>
            123
          </div> -->
        </div>
      </div>

    </b-card>

    <b-modal
      id="imageSiteModal"
      no-close-on-backdrop
      no-close-on-esc
      centered
      size="lg"
      body-class="p-0"
      @hidden="handleHideSiteModal"
      @close="handleHideSiteModal"
    >
      <template #modal-title>
        <h4 class="m-0">
          圖片轉換
        </h4>
      </template>

      <div class="border p-1 mx-1 mt-1 border-radius-default">
        <h5>轉換設定</h5>

        <b-row>
          <b-col
            cols="12"
            lg="4"
            md="12"
            sm="12"
          >
            <b-form-group
              label="轉檔類型"
              label-for="imageType"
            >
              <v-select
                v-model="imageType"
                :options="imageTypeOptions"
                :clearable="false"
                :reduce="option => option.value"
                placeholder="轉檔類型"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="4"
            md="6"
            sm="6"
          >
            <b-form-group
              label="寬度(px)"
              label-for="imageWidth"
            >
              <b-form-input
                id="imageWidth"
                v-model="imageWidth"
                placeholder="自動"
                type="number"
                @input="resizeInputWidth"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="4"
            md="6"
            sm="6"
          >
            <b-form-group
              label="高度(px)"
              label-for="imageHeight"
            >
              <b-form-input
                id="imageHeight"
                v-model="imageHeight"
                placeholder="自動"
                type="number"
                @input="resizeInputHeight"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-checkbox
          id="imageResize"
          v-model="imageResize"
          name="imageResize"
          @input="resizeInputProportion"
        >
          維持長寬比
        </b-form-checkbox>
      </div>

      <div class="site-modal p-1">
        <b-row class="match-height image-view-container">
          <b-col
            v-for="(image, index) in previewFile"
            :key="index"
            cols="12"
            xl="4"
            lg="6"
            class="mb-1 d-flex align-items-center"
          >
            <div
              class="image-view"
              :class="!isDark ? 'image-light' : 'image-view-dark'"
            >
              <div class="image-view-header">
                <div class="image-view-title cursor-pointer">
                  <span
                    v-b-tooltip.hover.focus.v-secondary
                    :title="image.name"
                    class="image-view-title-text"
                  >
                    {{ image.name }}
                  </span>
                </div>
              </div>

              <div class="image-view-body">
                <span
                  class="image-view-remove cursor-pointer"
                  @click="handleRemoveSite(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="remove-icon "
                  />
                </span>

                <div
                  class="w-100 h-100 d-flex align-items-center justify-content-center"
                  @click="showImgs(index)"
                >
                  <b-img-lazy
                    :src="image.src"
                    fluid
                    :alt="image.name"
                  />
                </div>
              </div>

              <div class="image-view-footer">

                <div class="d-flex align-items-center mt-50">
                  <b-badge pill>
                    {{ image.naturalWidth }} x {{ image.naturalHeight }}
                  </b-badge>
                  <!-- v-if="resize" -->
                  <feather-icon
                    v-if="image.naturalWidth !== image.resizeWidth || image.naturalHeight !== image.resizeHeight"
                    icon="ArrowRightIcon"
                    size="12"
                    class="mx-50"
                  />
                  <b-badge
                    v-if="image.naturalWidth !== image.resizeWidth || image.naturalHeight !== image.resizeHeight"
                    pill
                  >
                    {{ !image.resizeWidth ? image.naturalWidth : image.resizeWidth }} x {{ !image.resizeHeight ? image.naturalHeight : image.resizeHeight }}
                  </b-badge>
                </div>
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            xl="4"
            lg="6"
            class="mb-1 d-flex align-items-center"
          >
            <div
              class="image-view"
              :class="!isDark ? 'image-light' : 'image-view-dark'"
            >
              <div
                class="image-view-body cursor-pointer mt-lg-3 mt-1"
                @click="$refs.fileInputModal.click()"
              >
                <div
                  class="w-100 h-100 d-flex align-items-center justify-content-center"
                >
                  <b-img-lazy
                    src="/admin/images/image/plus.svg"
                    fluid
                    style="max-width: 50px; opacity: 0.6"
                    alt="新增"
                  />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <div>
            <b-button
              variant="flat-primary"
              :disabled="isUploadBusy"
              @click="$refs.fileInputModal.click()"
            >
              新增
            </b-button>

            <input
              ref="fileInputModal"
              type="file"
              multiple
              hidden
              accept=".jpg, .png, .gif .tiff, .bmp .ico .psd .webp"
              @input="handleAddSiteModal($event)"
            >
          </div>

          <div>
            <b-button
              variant="flat-primary"
              :disabled="isUploadBusy"
              @click="handleHideSiteModal"
            >
              取消
            </b-button>

            <b-button
              variant="flat-primary"
              :disabled="isUploadBusy"
              @click="handleConfirmSiteModal"
            >
              確認
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

    <!-- 歷史紀錄 -->
    <image-history ref="imageHistory" />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { onUnmounted, computed, ref } from '@vue/composition-api'
import {
  BCard, BImg, BModal, BRow, BCol, BButton, BImgLazy, VBTooltip, BFormInput, BFormGroup, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useImageSetting, useImageList } from './useImage'
import { useAlert } from '@/libs/mixins/index'
import store from '@/store'
import useStoreModule from './useStoreModule'
import ImageHistory from './ImageHistory.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BBadge,
    BModal,
    BButton,
    BImgLazy,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    vSelect,

    ImageHistory,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      siteFile: [],
      previewFile: [],
      siteIndex: null,
    }
  },
  computed: {
    resizeTarget() {
      if (!this.imageWidth) return 'height'
      if (!this.imageHeight) return 'width'
      return this.imageWidth < this.imageHeight ? 'width' : 'height'
    },
  },
  methods: {
    // (調整)寬度
    resizeInputWidth() {
      this.resize = false
      this.imageWidth = Math.round(this.imageWidth)
      // if (this.imageWidth === 0) return

      this.previewFile.forEach((item, index) => {
        this.previewFile[index].resizeWidth = this.imageWidth
        if (this.imageResize) {
          this.previewFile[index].resizeHeight = Math.round(this.previewFile[index].resizeWidth / this.previewFile[index].ratio)
        }
      })
      this.resize = true
    },

    // (調整)高度
    resizeInputHeight() {
      this.resize = false
      this.imageHeight = Math.round(this.imageHeight)
      // if (this.imageHeight === 0) return

      this.previewFile.forEach((item, index) => {
        this.previewFile[index].resizeHeight = this.imageHeight
        if (this.imageResize) {
          this.previewFile[index].resizeWidth = Math.round(this.previewFile[index].resizeHeight * this.previewFile[index].ratio)
        }
      })
      this.resize = true
    },

    // 是否維持原比例
    resizeInputProportion() {
      this.previewFile.forEach((item, index) => {
        if (!this.imageResize) {
          this.previewFile[index].resizeWidth = this.imageWidth ? this.imageWidth : this.previewFile[index].naturalWidth
          this.previewFile[index].resizeHeight = this.imageHeight ? this.imageHeight : this.previewFile[index].naturalHeight
          return
        }
        if (this.resizeTarget === 'width') {
          this.previewFile[index].resizeWidth = this.imageWidth
          this.previewFile[index].resizeHeight = Math.round(this.previewFile[index].resizeWidth / this.previewFile[index].ratio)
        } else {
          this.previewFile[index].resizeHeight = this.imageHeight
          this.previewFile[index].resizeWidth = Math.round(this.previewFile[index].resizeHeight * this.previewFile[index].ratio)
        }
      })
    },

    // (轉化)檔案大小
    convertBytes(bytes) {
      const kilobytes = (bytes / 1024).toFixed(1)
      const gigabytes = (kilobytes / (1024 * 1024)).toFixed(1)
      const megabytes = (kilobytes / 1024).toFixed(1)

      if (kilobytes >= 1024 * 1024) return `${gigabytes.toLocaleString()} GB`
      if (kilobytes >= 1024) return `${megabytes.toLocaleString()} MB`
      return `${kilobytes.toLocaleString()} KB`
    },
    // ------------------------------------------------------------------------------------
    preventDefault(e) {
      e.stopPropagation() // 終止事件傳導
      e.preventDefault() // 終止預設行為
    },

    dragEnter(e) {
      this.preventDefault(e)
      this.elementNode = e.target
      const { types } = e.dataTransfer
      if (types.includes('Files')) {
        this.$refs.dropArea.classList.remove('hidden')
      }
    },

    dragLeave(e) {
      this.preventDefault(e)
      if (this.elementNode === e.target) this.$refs.dropArea.classList.add('hidden')
    },

    dropFile(e) {
      this.preventDefault(e)
      const { files, types } = e.dataTransfer
      this.siteFile = Object.values(files)

      if (!types.includes('Files')) return
      if (this.siteFile.length === 0) return
      // this.$refs.dropArea.classList.add('hidden')

      files.forEach(file => {
        // const previewList = {
        //   file,
        //   src: null,
        //   name: file.name,
        // }
        // const objectURL = window.URL.createObjectURL(file)
        // previewList.src = objectURL
        // this.previewFile.push(previewList)

        const image = new Image()
        const resolveFile = {}
        const objectURL = window.URL.createObjectURL(file)

        const type = this.imageTypeList[file.type]
        if (!type) return

        resolveFile.name = file.name
        resolveFile.image = file
        resolveFile.type = type
        image.src = objectURL
        resolveFile.src = objectURL
        resolveFile.size = this.convertBytes(file.size)
        image.onload = () => {
          resolveFile.ratio = image.naturalWidth / image.naturalHeight
          resolveFile.naturalWidth = image.naturalWidth
          resolveFile.naturalHeight = image.naturalHeight
          resolveFile.resizeWidth = image.naturalWidth
          resolveFile.resizeHeight = image.naturalHeight
          this.previewFile.push(resolveFile)
        }
      })
      this.$bvModal.show('imageSiteModal')
      this.$refs.dropArea.classList.add('hidden')
    },

    resolveFileInput(e) {
      // this.preventDefault(e)
      if (e.target.files.length === 0) return
      const { files } = e.target
      this.siteFile = Object.values(files)
      files.forEach(file => {
        // const previewList = {
        //   file,
        //   src: null,
        //   name: file.name,
        // }
        // const objectURL = window.URL.createObjectURL(file)
        // previewList.src = objectURL
        // this.previewFile.push(previewList)

        const image = new Image()
        const resolveFile = {}
        const objectURL = window.URL.createObjectURL(file)

        const type = this.imageTypeList[file.type]
        if (!type) return

        resolveFile.name = file.name
        resolveFile.image = file
        resolveFile.type = type
        image.src = objectURL
        resolveFile.src = objectURL
        resolveFile.size = this.convertBytes(file.size)
        image.onload = () => {
          resolveFile.ratio = image.naturalWidth / image.naturalHeight
          resolveFile.naturalWidth = image.naturalWidth
          resolveFile.naturalHeight = image.naturalHeight
          resolveFile.resizeWidth = image.naturalWidth
          resolveFile.resizeHeight = image.naturalHeight
          this.previewFile.push(resolveFile)
        }
      })
      this.$bvModal.show('imageSiteModal')
      this.$refs.fileInput = null
    },

    // ------------------------------------------------------------------------------------
    // (新增)檔案(V)
    handleAddSiteModal(e) {
      if (this.isUploadBusy) return
      const { files } = e.target
      this.siteFile.push(Object.values(files))
      files.forEach(file => {
        const image = new Image()
        const resolveFile = {}
        const objectURL = window.URL.createObjectURL(file)

        const type = this.imageTypeList[file.type]
        if (!type) return

        resolveFile.name = file.name
        resolveFile.image = file
        resolveFile.type = type
        image.src = objectURL
        resolveFile.src = objectURL
        resolveFile.size = this.convertBytes(file.size)
        image.onload = () => {
          resolveFile.ratio = image.naturalWidth / image.naturalHeight
          resolveFile.naturalWidth = image.naturalWidth
          resolveFile.naturalHeight = image.naturalHeight
          resolveFile.resizeWidth = image.naturalWidth
          resolveFile.resizeHeight = image.naturalHeight
          this.previewFile.push(resolveFile)
        }
      })
    },

    // (觸發)變更圖片
    handleChangeSite(index) {
      if (index === null) return
      this.$refs.fileChange.click()
      this.siteIndex = index
    },

    // (觸發)圖片顯示
    showImgs(index) {
      const newArray = this.previewFile.slice(index).concat(this.previewFile.slice(0, index)).map(item => item.src)
      this.$viewerApi({
        images: newArray,
        options: {
          navbar: false,
          movable: false,
        },
      })
    },

    // (觸發)移除圖片
    handleRemoveSite(index) {
      if (this.isUploadBusy) return
      this.previewFile.splice(index, 1)
      if (this.previewFile.length === 0) this.handleHideSiteModal()
    },

    // (關閉)彈窗
    handleHideSiteModal() {
      // if (this.isUploadBusy) return
      this.$bvModal.hide('imageSiteModal')
      this.siteFile = []
      this.previewFile = []
      this.isUploadBusy = false
    },

    // (確認)彈窗
    async handleConfirmSiteModal() {
      this.isUploadBusy = true

      this.responseNum = this.previewFile.length
      const newUploadNum = this.previewFile.length

      if (this.imageType === 'ico' && (!this.imageHeight && !this.imageWidth)) {
        this.useAlertToast(false, 'ICO格式需設定至少一個長寬')
        this.isUploadBusy = false
        return
      }

      this.$bvModal.hide('imageSiteModal')

      this.previewFile.forEach(fileItem => {
        const { image } = fileItem
        const formData = new FormData()
        formData.append('data', image)
        formData.append('convert_extension', this.imageType)
        formData.append('convert_high', fileItem.resizeHeight ? fileItem.resizeHeight : fileItem.naturalHeight)
        formData.append('convert_width', fileItem.resizeWidth ? fileItem.resizeWidth : fileItem.naturalWidth)

        this.setImageUpload({
          data: formData,
        })
          .then(upload => {
            this.responseNum -= 1
            const uploadData = upload.data.data
            const resolve = {
              ...this.syncObject(this.blankImageData, uploadData),
            }
            resolve.busy = true
            resolve.status = 'convert'
            this.$refs.imageHistory.createTableData(resolve)
            if (this.responseNum === 0) {
              this.$refs.imageHistory.setTableTimer(newUploadNum)
              this.handleHideSiteModal()
              this.resetModal()
            }
            this.isUploadBusy = false
          })
          .catch(() => {
            this.responseNum -= 1
            if (this.responseNum === 0) {
              this.$refs.imageHistory.setTableTimer()
              this.handleHideSiteModal()
              this.resetModal()
            }
            this.isUploadBusy = false
          })
      })
    },
  },
  setup() {
    // 註冊模組
    const IMAGE_PAGE_ADMIN_STORE_MODULE_NAME = 'admin-page-image'

    if (!store.hasModule(IMAGE_PAGE_ADMIN_STORE_MODULE_NAME)) store.registerModule(IMAGE_PAGE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(IMAGE_PAGE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(IMAGE_PAGE_ADMIN_STORE_MODULE_NAME)
    })

    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    const isUploadBusy = ref(false)
    const resize = ref(false)
    const responseNum = ref(0)

    const {
      blankImageData,
    } = useImageList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      imageTypeOptions,
      imageTypeList,
      imageType,
      imageResize,
      imageHeight,
      imageWidth,

      setImageUpload,
      syncObject,
    } = useImageSetting()

    const resetModal = () => {
      resize.value = false
      imageResize.value = false
      imageHeight.value = null
      imageWidth.value = null
      imageType.value = 'png'
    }

    return {
      isDark,
      isUploadBusy,

      imageTypeOptions,
      imageTypeList,
      imageType,
      imageResize,
      imageHeight,
      imageWidth,
      resize,
      responseNum,
      setImageUpload,
      syncObject,
      resetModal,

      blankImageData,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.upload-card {
  .dropArea {
    display: block;
    min-height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding: 0 20px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #282828;
    opacity: .8;
    // backdrop-filter: blur(10px);
    text-align: center;
    color: #fff;
    // text-shadow: 0em 0.01em #333, 0em 0.02em #333, 0em 0.02em 0.03em #333;
    &.hidden {
      display: none;
    }
  }
  .upload-label {
    width: 100%;
    text-align: center;
    border-radius: 10px;
    height: 15vh;
    border: #898989 dashed 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .label-title {
      display: block;
      font-size: 1.5rem;
      font-weight: 900;
      padding: 1rem 0 0.5rem;
    }
    .label-subtitle {
      font-size: 1rem;
    }
    img {
      max-width: 50px;
    }
  }

  .upload-label-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 1rem;
    // font-weight: 900;
    min-height: 20vh;
    padding: 1rem;
    // font-size: 1.5rem;
    cursor: pointer;
    img {
      max-width: 50px;
    }
  }
}

.events-content {
  pointer-events: none;
}

.site-modal {
  max-height: 60vh;
  overflow-y:auto;
  overflow-x: hidden;
}

.image-view-container {
  margin-top: 10px;
  .image-view {
    position: relative;
    padding: 5px;
    width: 100%;
    .image-view-header {
      display: flex;
      justify-content: center;

      .image-view-title {
        padding: 10px 0;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        .image-view-title-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 140px;
        }
      }
    }

    .image-view-body {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 180px;
      padding: 10px;
      border-radius: 10px;
      img {
        max-height: 160px;
        cursor: pointer;
      }

      .image-view-remove {
        position: absolute;
        top: -10px;
        right: -6px;
        color: white;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        text-align: center;
        opacity: 0;
        &:hover {
          background-color: #c40c0cb3;
        }
      }
    }

    .image-view-footer {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      border-radius: 10px;
      border: #4683bb 1px solid;
      margin: -1px;

      .image-view-body {
        .image-view-remove {
          opacity: 1;
        }
      }
    }
  }

  .image-light {
    .image-view-body {
      background-color:#00000010;
      .image-view-remove {
        background-color: #3c3c3cb3;
      }
    }
  }

  .image-view-dark {
    .image-view-body {
      background-color:#ffffff10;
      .image-view-remove {
        background-color: #898989;
      }
    }
  }
}

</style>
