<template>
  <div>
    <!-- 更新圖片名稱 -->
    <update-modal
      v-if="selected"
      ref="updateModal"
      :image-data-item="selected"
      @refetch-data="refetchTable"
    />

    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="7"
          >
            <h4 class="mb-0">
              歷史紀錄
            </h4>
            <small>圖片會有一周(7天)的保留時間，超過一周的檔案系統將會自動移除</small>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="5"
            class="mt-50"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchData"
              >
                <b-img
                  src="/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <!-- <label class="text-nowrap">顯示</label> -->
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="tableData.slice(0, perPage)"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        :busy="isBusy"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 狀態 -->
        <template #cell(status)="data">
          <b-badge :variant="ui.statusList[1][data.item.status]">
            {{ ui.statusList[0][data.item.status] }}
          </b-badge>
        </template>

        <!-- 欄位: 檔案名稱 -->
        <template #cell(name)="data">
          <div
            class="font-weight-bold d-block text-nowrap item-description text-primary"
            @click="onSubmitUpdate(data.item)"
          >
            {{ data.item.name }}
          </div>
          <!-- <small class="text-muted">
            <div class="item-description">
              {{ data.item.department_id && data.item.department_info ? data.item.department_info.name : '' }}
            </div>
          </small> -->
        </template>

        <!-- 欄位: 原始大小 -->
        <template #cell(size)="data">
          <div class="table-col text-nowrap">
            {{ data.item.size ? convertBytes(parseInt(data.item.size, 10)) : '---' }}
          </div>
        </template>

        <!-- 欄位: 原始類型 -->
        <template #cell(extension)="data">
          <div
            class="table-col btn-image-convert-bind"
            @click="onSubmitDownload('raw', data.item)"
          >
            <b-img
              v-if="isExtensionType(data.item.extension) !== 'raw'"
              v-b-tooltip.hover.focus.v-secondary
              title="下載原始圖片"
              :src="`/admin/images/image/${data.item.extension}.png`"
              class="btn-image-bind"
              rounded
            />

            <div v-else>
              <b-img
                src="/admin/images/image/raw.svg"
                class="btn-image-empty"
                rounded
              />
            </div>
          </div>
        </template>

        <!-- 欄位: 轉檔大小 -->
        <template #cell(convert_size)="data">
          <div v-if="data.item.status === 'convert' || data.item.busy">
            <b-img
              src="/admin/images/common/loading-2.png"
              rounded
              height="25"
              width="25"
              alt="loading"
            />
          </div>

          <div
            v-else-if="data.item.status === 'fail'"
            class="btn-image-convert-bind d-inline-block"
          >
            <b-img
              v-if="data.item.status === 'fail'"
              v-b-tooltip.hover.focus.v-secondary
              title="轉碼失敗"
              src="/admin/images/table/danger.svg"
              class="btn-image-bind ml-25"
              rounded
              @click="onSubmitReload(data.item)"
            />
          </div>

          <div
            v-else
            class="table-col text-nowrap"
          >
            {{ data.item.convert_size ? convertBytes(parseInt(data.item.convert_size, 10)) : '---' }}
          </div>
        </template>

        <!-- 欄位: 轉檔類型 -->
        <template #cell(convert_extension)="data">
          <div
            class="table-col btn-image-convert-bind"
            @click="onSubmitDownload('convert', data.item)"
          >
            <b-img
              v-if="isExtensionType(data.item.convert_extension) !== 'raw'"
              v-b-tooltip.hover.focus.v-secondary
              title="下載轉檔圖片"
              :src="`/admin/images/image/${data.item.convert_extension}.png`"
              class="btn-image-bind"
              rounded
            />

            <div v-else>
              <b-img
                src="/admin/images/image/raw.png"
                class="btn-image-empty"
                rounded
              />
            </div>

            <!-- <b-img
              v-if="data.item.status === 'fail'"
              v-b-tooltip.hover.focus.v-secondary
              title="轉碼失敗"
              src="/admin/images/table/danger.svg"
              class="btn-image-bind ml-25"
              rounded
              @click="onSubmitReload(data.item)"
            /> -->

          </div>
        </template>

        <!-- 欄位: 創建時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <div class="d-flex mb-50">
            <div
              v-if="data.item.status === 'finished'"
              class="d-flex"
            >
              <div
                class="actions-link-btn mr-25"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                class="actions-link-btn mr-25"
                @click="onSubmitDelete(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="刪除"
                  src="/admin/images/table/delete.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>

            <div
              v-else-if="data.item.status === 'convert' || data.item.busy"
              class="my-50"
            >
              <b-img
                src="/admin/images/common/loading-2.png"
                rounded
                height="25"
                width="25"
                alt="loading"
              />
            </div>

            <div
              v-else
              class="d-flex"
            >
              <div
                class="actions-link-btn mr-25"
                @click="onSubmitReload(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新轉檔"
                  src="/admin/images/table/reset.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                class="actions-link-btn mr-25"
                @click="onSubmitDelete(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="刪除"
                  src="/admin/images/table/delete.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>
<script>
// API
// import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BBadge,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend, // BModal, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import router from '@/router'
// import store from '@/store'

// Component
import { useImageList, useImageSetting } from './useImage'
// import UpdateModal from './ClassListUpdateModal.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import UpdateModal from './ImageHistoryUpdateModal.vue'
// import useStoreModule from '../useStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    // BLink,
    BFormInput,
    BTable,
    BBadge,
    // BModal,
    BPagination,
    BDropdown,
    // BDropdownItem,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    UpdateModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    tableColumnsFilter() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.select && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.searchable && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.searchable)
    },
    authAbility() {
      return this.$store.state.app.ability
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (轉化)檔案大小
    convertBytes(bytes) {
      const kilobytes = (bytes / 1024).toFixed(1)
      const gigabytes = (kilobytes / (1024 * 1024)).toFixed(1)
      const megabytes = (kilobytes / 1024).toFixed(1)

      if (kilobytes >= 1024 * 1024) return `${gigabytes.toLocaleString()} GB`
      if (kilobytes >= 1024) return `${megabytes.toLocaleString()} MB`
      return `${kilobytes.toLocaleString()} KB`
    },

    // (判斷)是否在陣列中
    isExtensionType(type) {
      const typeList = ['jpg', 'png', 'gif', 'tiff', 'bmp', 'ico', 'psd', 'webp']
      const result = typeList.includes(type ? type.toLowerCase() : null)
      if (result) return type
      return 'raw'
    },

    // (創建)新的圖片紀錄
    createTableData(data) {
      this.tableData.unshift(data)
      // setTimeout(() => {
      //   this.onSubmitReload(data)
      // }, 1000)
    },

    // (觸發)下載
    async onSubmitDownload(type, item) {
      try {
        const response = await this.getImageDownload({
          image_id: item.id,
          type,
        })

        const { key } = response.data.data

        // 創建一個<a>元素
        const link = document.createElement('a')
        link.href = `/file/download/image/convert?key=${key}`

        // 模擬點擊下載連結
        link.click()
      } catch (error) {
        this.useHttpCodeAlert(error.response)
      }
    },

    // (觸發)圖片轉檔
    onSubmitReload(item) {
      const indexOfEl = this.tableData.findIndex(el => el.id === item.id)

      if (indexOfEl !== -1) {
        this.tableData[indexOfEl].busy = true
        this.tableData[indexOfEl].status = 'convert'
      }
      setTimeout(() => {
        this.setImageReload({
          image_id: item.id,
        })
          .then(response => {
            // this.tableData[indexOfEl].busy = false
            // this.tableData[indexOfEl].status = 'finished'
            const uploadData = response.data.data
            const resolve = {
              ...this.syncObject(this.blankImageData, uploadData),
            }
            if (indexOfEl !== -1) {
              this.tableData.splice(indexOfEl, 1, resolve)
            }
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.tableData[indexOfEl].busy = false
            this.tableData[indexOfEl].status = 'upload'
          })
      }, 1000)
    },

    // (編輯)圖片
    onSubmitUpdate(item) {
      this.selected = item
      setTimeout(() => { this.$refs.updateModal.getData() }, 200)
    },

    // (刪除)圖片
    onSubmitDelete(item) {
      // if (this.authAbility.user !== 'write') return
      this.useSwalAlertWarning('刪除圖片', `你確定要永久刪除圖片 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setImageDelete({
              image_id: item.id,
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', '圖片已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    const {
      refDataListTable,
      tableData,
      isBusy,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,
      deviceTypeList,
      deviceTypeOptions,
      resolveDeviceType,
      blankImageData,

      getImageListData,
      responseNum,
      setTableTimer,
    } = useImageList()

    getImageListData()

    const {
      ui,
      // statusOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      getImageDownload,
      setImageReload,
      getImageData,
      setImageDelete,
      syncObject,
    } = useImageSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      ui,
      useAlertToast,
      useHttpCodeAlert,
      // statusOptions,
      refDataListTable,
      tableData,
      isBusy,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,
      deviceTypeList,
      deviceTypeOptions,
      resolveDeviceType,
      blankImageData,

      getImageListData,
      responseNum,
      setTableTimer,
      refonlineTime,
      onlineTime,
      updateOnline,
      getImageDownload,
      setImageReload,
      setImageDelete,
      getImageData,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.item-description {
  // background-color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  // font-size: 1.4rem;
}

.btn-image-convert-bind {
  display: flex;
  img {
    height: 16px;
    // width: 30px;
  }
  .btn-image-bind:hover {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.2);
  }

  .btn-image-empty {
    opacity: 0.3;
  }
}
</style>
