import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)轉換歷史紀錄
    getImageList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/image', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (確認)轉換狀況
    getImageData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/image/${resolveData.image_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (下載)圖片(raw / convert)
    getImageDownload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/image/${resolveData.image_id}/download/${resolveData.type}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)圖片
    setImageUpload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/image/upload', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (重新)轉碼
    setImageReload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/image/${resolveData.image_id}/reconvert`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)圖片
    setImageUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/image/${resolveData.image_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)圖片
    setImageDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/image/${resolveData.image_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
